import React from "react";
import EmployeeForm from "./components/form";
import LoginForm from "./components/login";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Dashboard from "./components/dashboard";
import { Toaster, toast } from 'sonner'

function App() {
  return (
    <div>
       <Toaster richColors  />
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm/>} />
        <Route path="/employee" element={<EmployeeForm/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
