import { useEffect, useState } from "react";
import axios from "axios";
import {BallTriangle} from "react-loader-spinner"
import { BASE_URL } from "../apiUrl";

const Dashboard = () => {
  const [employees, setEmployees] = useState([]);

  const nowDate = new Date();
  const date =
    nowDate.getFullYear() +
    "-" +
    "0" +
    (nowDate.getMonth() + 1) +
    "-" +
    nowDate.getDate();
  const [currentDate, setCurrentDate] = useState(date);
  const [isLoading, setIsLoading] = useState(true);
  console.log(date);
  useEffect(() => {
    console.log(currentDate);
    console.log(isLoading);
    // Fetch data from the backend API
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch daily work hours
        const dailyResponse = await axios.post(
          `${BASE_URL}/workHours/day`,
          { date: currentDate }
        );
        const dailyData = dailyResponse.data;
        console.log(dailyResponse.data);
        dailyData ? setIsLoading(false) : setIsLoading(true);

        // Fetch weekly work hours
        const weeklyResponse = await axios.post(
          `${BASE_URL}/workHours/week`,
          { date: currentDate }
        );
        const weeklyData = weeklyResponse.data;
        // console.log(weeklyResponse.data);
        weeklyData ? setIsLoading(false) : setIsLoading(true);
        // Fetch monthly work hours
        const monthlyResponse = await axios.post(
          `${BASE_URL}/workHours/month`,
          { date: currentDate }
        );
        const monthlyData = monthlyResponse.data;
        // console.log(monthlyResponse.data);
        monthlyData ? setIsLoading(false) : setIsLoading(true);
        // Combine the data from all endpoints
        const combinedData = mergeData(dailyData, weeklyData, monthlyData);
        setEmployees(combinedData);
        // console.log(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentDate]);

  // Function to merge the data from different endpoints
  const mergeData = (dailyData, weeklyData, monthlyData) => {
    // Assuming your data has employee IDs
    // Here, we're merging the data based on employee ID
    const mergedData = dailyData.map((employee) => {
      const dailyEmployee = dailyData.find((e) => e.name === employee.name);
      const weeklyEmployee = weeklyData.find((e) => e.name === employee.name);
      const monthlyEmployee = monthlyData.find((e) => e.name === employee.name);

      // Merge the data
      return {
        id: employee.employeeId,
        name: employee.name,
        dailyHours: dailyEmployee.totalWorkingHours,
        weeklyHours: weeklyEmployee ? weeklyEmployee.totalWorkingHours : 0,
        monthlyHours: monthlyEmployee ? monthlyEmployee.totalWorkingHours : 0,
      };
    });

    return mergedData;
  };

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-bold mb-6 ml-20 text-blue-500">Working Hours Dashboard</h1>
      <div className="p-3 shadow-sm">
        <form>
          <input
            placeholder="Select Date"
            type="date"
            className="p-2 border border-gray-200 w-[40%] rounded-md"
            onChange={(e) => setCurrentDate(e.target.value)}
          />
          {/* <button className="rounded-md bg-blue-400 p-2 mx-2 px-10">
            Search
  </button> */}
        </form>
      </div>
      {isLoading && (
        <div className="flex w-full h-[60vh] justify-center items-center text-2xl text-blue-400">
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#2596be"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {/* grid grid-cols-1 md:grid-cols-3 gap-6 */}
      {/* block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {!isLoading &&
          employees.map((employee) => (
            <div
              key={employee.id}
              className=" shadow-md rounded-lg p-6 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h2 className="text-2xl text-blue-400 font-semibold mb-4">
                {employee?.name}
              </h2>
              <div className="mb-4">
                <h3 className="text-lg font-medium">Daily Working Hours</h3>
                <p className="text-gray-600">{employee?.dailyHours} hours</p>
              </div>
              <div className="mb-4">
                <h3 className="text-lg font-medium">Weekly Working Hours</h3>
                <p className="text-gray-600">{employee?.weeklyHours} hours</p>
              </div>
              <div>
                <h3 className="text-lg font-medium">Monthly Working Hours</h3>
                <p className="text-gray-600">{employee?.monthlyHours} hours</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;

// import { useEffect, useState } from 'react';
// import axios from 'axios';

// const Dashboard = () => {
//   const [employees, setEmployees] = useState([]);

//   useEffect(() => {
//     // Fetch data from the backend API
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('ttp://localhost:3001/api/');
//         setEmployees(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className="container mx-auto mt-8">
//       <h1 className="text-3xl font-bold mb-6">Employee Working Hours Dashboard</h1>
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//         {employees.map((employee) => (
//           <div key={employee.id} className="bg-white shadow-md rounded-lg p-6">
//             <h2 className="text-xl font-semibold mb-4">{employee.name}</h2>
//             <div className="mb-4">
//               <h3 className="text-lg font-medium">Daily Working Hours</h3>
//               <p className="text-gray-600">{employee.dailyHours} hours</p>
//             </div>
//             <div className="mb-4">
//               <h3 className="text-lg font-medium">Weekly Working Hours</h3>
//               <p className="text-gray-600">{employee.weeklyHours} hours</p>
//             </div>
//             <div>
//               <h3 className="text-lg font-medium">Monthly Working Hours</h3>
//               <p className="text-gray-600">{employee.monthlyHours} hours</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Dashboard;
