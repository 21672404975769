import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { BASE_URL } from "../apiUrl";
// import api from './api'

const LoginForm = () => {
  const [name, setName] = useState("");
  const [workId, setWorkId] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setName(e.target.value);
  };

  const handleIdNumberChange = (e) => {
    setWorkId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/employees/login`,
        {
          name,
          workId,
        }
      );
      console.log("Response data:", response.data);

      if (response.data.employee.role === "admin") {
        // alert('Successfully logged in');
        toast.success(response.data.message)
        navigate("/dashboard");
      } else if (response.data.employee.role === "employee") {
        navigate("/employee");
      } else {
        console.log(
          "Authentication failed:",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      // console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error.message);
      } else {
        // alert(error.message);
        toast.error(error.response.data.message)
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Here you can handle the form submission, such as sending the username and password to your backend for authentication
  //   try {
  //     const response = await axios.post("/api/employees/login", {
  //       name,
  //       idNumber,
  //     })
  //     .then((response) => {
  //       console.log("Response data:", response.data)
  //       alert('Successfully logged in')
  //     })

  //     if (response.data && response.data.success) {
  //       navigate("/employee");
  //     } else {
  //       console.log("Authentication failed:", response.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     console.log(error.response)
  //     alert(error.response.data.error.message)
  //   }
  // };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-gray-200 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Name:
          </label>
          <input
            type="text"
            id="username"
            value={name}
            onChange={handleUsernameChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            ID Number:
          </label>
          <input
            type="number"
            id="idNumber"
            value={workId}
            onChange={handleIdNumberChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
